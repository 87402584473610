import React from 'react';
import { Provider } from 'react-redux';
import Loadable from '@loadable/component';
import { Router, Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';

import {
  APP_NAME,
  APP_VERSION,
  APP_DESCRIPTION,
  APP_SHA,
  WEBSITE_TITLE_TEMPLATE,
} from 'services/constants';
import store from 'services/store';
import history from 'services/history';
import Loading from 'components/Loading';
import RouterQuery from 'components/RouterQuery';

const loadableOptions = {
  fallback: <Loading />,
};

const Main = Loadable(() => import('scenes/Main'), loadableOptions);
const Forbidden = Loadable(() => import('scenes/Forbidden'), loadableOptions);

function App() {
  return (
    <ConfigProvider locale={zhCN}>
      <Provider store={store}>
        <Router history={history}>
          <RouterQuery>
            <>
              <Helmet titleTemplate={WEBSITE_TITLE_TEMPLATE}>
                <meta
                  name="app-meta"
                  content={`name=${APP_NAME},version=${APP_VERSION},description=${APP_DESCRIPTION},sha=${APP_SHA}`}
                />
              </Helmet>
              <Switch>
                <Route path="/403" component={Forbidden} />
                <Route path="/" component={Main} />
              </Switch>
            </>
          </RouterQuery>
        </Router>
      </Provider>
    </ConfigProvider>
  );
}

export default App;
